import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import PageLayout from "../components/layout/PageLayout";
import { 
    HeroSection, 
    DescriptionSection, 
    GalleryThumbnailsSection, 
} from '../components/sections';
import Seo from "../components/seo";

import { 
    HERO_CONFIG,
    DESCRIPTION_CONFIG,
} from '../content/history_content_config';

const HistoryPage = ({ location }) => {
    const [isPageScrollLocked, setIsPageScrollLocked] = useState(false);

    const historyGraphicQuery = useStaticQuery(
        graphql`
            {
                historyHeroImage: file(relativePath: { eq: "historia-hero.jpg"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 70)
                    }
                }
                galleryImage1: file(relativePath: { eq: "DD_historia_1_dworek.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage2: file(relativePath: { eq: "DD_historia_2_dworek.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage3: file(relativePath: { eq: "DD_historia_3_akt.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage4: file(relativePath: { eq: "DD_historia_4_budowa.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage5: file(relativePath: { eq: "DD_historia_5_budowa.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage6: file(relativePath: { eq: "DD_historia_6_budowa.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage7: file(relativePath: { eq: "DD_historia_7_budowa.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage8: file(relativePath: { eq: "DD_historia_8_budowa.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage9: file(relativePath: { eq: "DD_historia_9_budowa.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage10: file(relativePath: { eq: "DD_historia_10_budowa.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage11: file(relativePath: { eq: "DD_historia_11_budowa.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage12: file(relativePath: { eq: "DD_historia_12_budowa.png"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage13: file(relativePath: { eq: "DD_historia_13_otwarcie.jpg"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage14: file(relativePath: { eq: "DD_historia_14_otwarcie.jpg"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage15: file(relativePath: { eq: "DD_historia_15_otwarcie.jpg"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage16: file(relativePath: { eq: "DD_historia_16_otwarcie.jpg"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage17: file(relativePath: { eq: "DD_historia_17_otwarcie.jpg"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage18: file(relativePath: { eq: "DD_historia_18_otwarcie.jpg"}) {
                childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage19: file(relativePath: { eq: "DD_historia_19_otwarcie.jpg"}) {
                childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                galleryImage20: file(relativePath: { eq: "DD_historia_20_otwarcie.jpg"}) {
                childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                ddJulin: file(relativePath: { eq: "julin.jpg"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                powBudowa: file(relativePath: { eq: "POW-budowa.jpg"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                powOtwarcie: file(relativePath: { eq: "POW-otwarcie.jpg"}) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        `
    );

    const galleryImagesItems = [
        {
            id: 1,
            name: "Zabytkowy pałac Ignacego i Heleny Paderewskich - dawna siedziba Domu Dziecka Julin w Kaliskach",
            image: getImage(historyGraphicQuery.galleryImage1),
        },
        {
            id: 2,
            name: "Główne wejście do pałacu w Kaliskach - dawnej siedziby Domu Dziecka",
            image: getImage(historyGraphicQuery.galleryImage2),
        },
        {
            id: 3,
            name: "Podpisanie aktu erekcyjnego budowy nowych domów dla dzieci",
            image: getImage(historyGraphicQuery.galleryImage3),
        },
        {
            id: 4,
            name: "Uroczystość wmurowania kamienia węgielnego pod budowę nowych domów dla dzieci",
            image: getImage(historyGraphicQuery.galleryImage4),
        },
        {
            id: 5,
            name: "Wychowanka domu dziecka wmurowuje akt erekcyjny w ścianę budynku",
            image: getImage(historyGraphicQuery.galleryImage5),
        },
        {
            id: 6,
            name: "Uczestnicy uroczystości podpisania aktu erekcyjnego",
            image: getImage(historyGraphicQuery.galleryImage6),
        },
        {
            id: 7,
            name: "Tablica informacyjna z budowy nowych placówek opiekuńczo-wychowawczych",
            image: getImage(historyGraphicQuery.galleryImage7),
        },
        {
            id: 8,
            name: "Zalewanie fundamentów budynków placówek opiekuńczo-wychowawczych",
            image: getImage(historyGraphicQuery.galleryImage8),
        },
        {
            id: 9,
            name: "Budowa konstrukcji dachowej na budynku placówki “Helenka”",
            image: getImage(historyGraphicQuery.galleryImage9),
        },
        {
            id: 10,
            name: "Budowa budynku placówki “Ignaś”",
            image: getImage(historyGraphicQuery.galleryImage10),
        },
        {
            id: 11,
            name: "Widok terenu budowy nowych domów dla dzieci",
            image: getImage(historyGraphicQuery.galleryImage11),
        },
        {
            id: 12,
            name: "Prace wykończeniowe wewnątrz budynku",
            image: getImage(historyGraphicQuery.galleryImage12),
        },
        {
            id: 13,
            name: "Drogowskazy wskazujące budynki “Ignaś” i “Helenka”",
            image: getImage(historyGraphicQuery.galleryImage13),
        },
        {
            id: 14,
            name: "Goście zgromadzeni na uroczystym otwarciu placówek opiekuńczo-wychowawczych",
            image: getImage(historyGraphicQuery.galleryImage14),
        },
        {
            id: 15,
            name: "Goście zgromadzeni na uroczystym otwarciu placówek opiekuńczo-wychowawczych",
            image: getImage(historyGraphicQuery.galleryImage15),
        },
        {
            id: 16,
            name: "Przecięcie wstęgi podczas uroczystego otwarcia nowych domów dla dzieci",
            image: getImage(historyGraphicQuery.galleryImage16),
        },
        {
            id: 17,
            name: "Poświęcenie domów przez biskupa Antoniego Dydycza",
            image: getImage(historyGraphicQuery.galleryImage17),
        },
        {
            id: 18,
            name: "Jadalnia w domu “Helenka”",
            image: getImage(historyGraphicQuery.galleryImage18),
        },
        {
            id: 19,
            name: "Miejsce do wypoczynku wychowanków w domu “Helenka”",
            image: getImage(historyGraphicQuery.galleryImage19),
        },
        {
            id: 20,
            name: "Pokój wychowanków w domu “Helenka”",
            image: getImage(historyGraphicQuery.galleryImage20),
        },
    ];
    
    return (
        <>
            <Seo
                title="Nasza historia | P.O.W Ignaś i Helenka w Kaliskach"
                description="Placówki Opiekuńczo-Wychowawcze Ignaś i Helenka w Kaliskach a dawniej Dom Dziecka Julin, posiadają bogatą historię związaną z Ignacym i Heleną Paderewskimi."
            />

            <PageLayout
                location={ location }
                isPageScrollLocked={ isPageScrollLocked }
                navigationTransparentOnTop
            >
                <HeroSection 
                    backgroundImage={ historyGraphicQuery.historyHeroImage }
                    title={ HERO_CONFIG.title }
                    alt="Zabytkowy pałac Ignacego i Heleny Paderewskich - dawna siedziba Domu Dziecka Julin w Kaliskach (zdjęcie od strony ogrodu)"
                />

                <DescriptionSection
                    config={ DESCRIPTION_CONFIG }
                    imagesQuery={ historyGraphicQuery }
                />

                <GalleryThumbnailsSection 
                    imagesQuery={ galleryImagesItems } 
                    setIsPageScrollLocked={ setIsPageScrollLocked } 
                />

            </PageLayout>
        </>
    );
};
 
export default HistoryPage;
