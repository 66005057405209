export const HERO_CONFIG = {
    title: 'Historia powstania P.O.W. Ignaś I Helenka W Kaliskach',
    alt: 'Grafika sekcji początkowej historia'
};

export const DESCRIPTION_CONFIG = {
    title: 'Historia',
    articles: [
        {
            id: 1,
            header: 'Powstanie Domu Dziecka w Kaliskach',
            content: [
                {
                    id: 1,
                    type: 'text',
                    text: 'Otwarcie Placówek Opiekuńczo-Wychowawczych w obecnej formie dwóch samodzielnych domów nastąpiło w dniu 1 lipca 2018 roku. Jednak historia domu dziecka w miejscowości Kaliska sięga aż 1919 roku. Przed dniem otwarcia nowych Placówek Dom Dziecka “Julin” swoją siedzibę miał w zabytkowym pałacyku  z 1904 roku, który był własnością rodziny Paderewskich.  Przed wojną, w 1919 roku, Helena Paderewska, żona wirtuoza i premiera Ignacego Jana Paderewskiego, w majątku Julin prowadziła szkołę hodowli drobiu i gospodarstwa domowego. W tym czasie w pałacyku był internat dla dziewcząt. Po wojnie w miejscu internatu urządzono dom dziecka.  W pierwszym okresie działalności jego podopiecznymi były przede wszystkim sieroty wojenne. Z czasem do placówki przyjmowane były dzieci z powodu różnych problemów rodzinnych (sieroctwa, ubóstwa, niepełnosprawności, przemocy, uzależnienia, niewydolności wychowawczej rodziców i innych).',
                },
                {
                    id: 2,
                    type: 'image',
                    image: 'DDJulin.jpg',
                    alt: 'Zabytkowy pałac Ignacego i Heleny Paderewskich - dawna siedziba Domu Dziecka Julin w Kaliskach (zdjęcie od strony ulicy)',
                }
            ] 
        },
        {
            id: 2,
            header: 'Dlaczego nastąpiła zmiana miejsca i jak to przebiegało',
            content: [
                {
                    id: 1,
                    type: 'text',
                    text: 'Ustawa z dnia 9 czerwca 2011 roku o wspieraniu rodziny i systemie pieczy zastępczej wprowadziła zmiany, które obligowały do stworzenia placówek dla grup nie większych niż 14 dzieci. Odpowiednie warunki lokalowe należało zabezpieczyć w terminie do 1 stycznia 2021 roku. Uwzględniając krajowe tendencje polityki społecznej i rodzinnej samorząd powiatu węgrowskiego podjął z kilkuletnim wyprzedzeniem działania zmierzające do deinstytucjonalizacji pieczy zastępczej. W związku z powyższym koniecznym stało się przekształcenie Domu Dziecka “Julin” w dwie placówki opiekuńczo-wychowawcze, którym nadano nazwy “Ignaś” i “Helenka”. Placówka Opiekuńczo-Wychowawcza “Ignaś” została placówką obsługującą i zapewnia placówce opiekuńczo-wychowawczej “Helenka” obsługę administracyjną, finansową i sprawozdawczą, organizacyjną i specjalistyczną. Placówka opiekuńczo - wychowawcza "Helenka" to placówka obsługiwana. W wyniku przemian zespół kadrowo-finansowy, który funkcjonował w strukturach Domu Dziecka „Julin” został wyodrębniony i przeniesiony w struktury Zespołu Szkół Ponadgimnazjalnych  w Łochowie w związku z czym znajduje się poza nowo otwartymi placówkami.',
                },
                {
                    id: 2,
                    type: 'text',
                    text: 'Nazwy domów są hołdem oddanym  właścicielom Pałacu Julin w Kaliskach Ignacemu Janowi Paderewskiemu  i jego żonie Helenie. Niezmiernie ważnym jest fakt, iż oddanie do użytku nowych domów przypadło na obchody setnej rocznicy odzyskania przez Polskę niepodległości w czym szczególne zasługi miał premier Ignacy Jan Paderewski. Ideą utworzenia tego typu domów było stworzenie dzieciom warunków jak najbardziej zbliżonych do warunków rodzinnych.',
                },
                {
                    id: 3,
                    type: 'text',
                    text: 'Wraz z postępującym  procesem zachodzących przemian w  pieczy zastępczej zmieniała się również specyfika pracy z dziećmi i młodzieżą. Funkcjonowanie wychowanków w nowych  domach ma przede wszystkim zmniejszyć grupę wychowawczą i stworzyć optymalne warunki do rozwoju młodego człowieka w każdej sferze: emocjonalnej, intelektualnej, fizycznej i społecznej oraz umożliwić nabywanie umiejętności niezbędnych do samodzielnego funkcjonowania w dorosłym życiu.',
                },
                {
                    id: 4,
                    type: 'text',
                    text: 'Małe, przypominające dom, instytucje mają służyć budowaniu indywidualnych więzi z opiekunem. Dając dziecku miłość i zainteresowanie, zapewniamy przeniesienie tej miłości na kolejne pokolenia, czyli budujemy zdrowe społeczeństwo.',
                },
                {
                    id: 5,
                    type: 'text',
                    text: 'W nowych domach czekały też nowe wyzwania dla wychowanków i wychowawców. Korzystając z pomocy wychowawcy dyżurującego wychowankowie organizują codzienne czynności w gospodarstwie domowym, ucząc się m.in. gotowania, prania, sprzątania i innych czynności niezbędnych do zaspokajania podstawowych potrzeb życiowych. Praca w kierunku wdrażania młodego człowieka do samodzielności, była prowadzona zawsze, ale przy aktywnym udziale osób zatrudnionych w placówce do obsługi. W nowej rzeczywistości, jak w każdym domu rodzinnym, osób wspierających czynności porządkowe i obsługowe nie ma już z nami.',
                },
                {
                    id: 7,
                    type: 'image',
                    image: 'POW_Budowa.png',
                    alt: 'Budowa Placówki Opiekuńczo-Wychowawczej “Ignaś” w Kaliskach',
                }
            ]
        },
        {
            id: 3,
            header: 'Budowa i otwarcie nowych domów',
            content: [
                {
                    id: 1,
                    type: 'text',
                    text: 'Dnia 5 grudnia 2016 r. budynek w którym funkcjonował Dom Dziecka "Julin" został zakupiony przez Urząd Marszałkowski Województwa Mazowieckiego. Placówka nadal funkcjonowała w dotychczasowym miejscu, na tych samych zasadach, a za środki pozyskane ze sprzedaży rozpoczęto budowę Domów dla Dzieci. Przez ponad półtora roku placówka funkcjonowała wspólnie z Mazowieckim Zespołem Parków Krajobrazowych, korzystając z gościnności nowych właścicieli.',
                },
                {
                    id: 2,
                    type: 'text',
                    text: 'Na podstawie zawartego porozumienia pomiędzy gminą Łochów i powiatem węgrowskim dokonano zamiany gruntów, która umożliwiła usytuowanie kampusów domów dla dzieci. W dniu 10 sierpnia 2016 roku zatwierdzono projekt budowlany i udzielono pozwolenia na budowę kampusów. Projekt został przygotowany przez Panią mag. inż. arch. Katarzynę Woźnicką. Wykonawcą robót, wyłonionym w trakcie przetargu, została firma ARCUS z Celestynowa, która w maju 2017 roku rozpoczęła budowę domów. Uroczystość wmurowania kamienia węgielnego pod budowę nowych Domów dla Dzieci odbyła się 30 czerwca 2017 roku. Podczas uroczystości, w której wzięli udział licznie zgromadzeni goście, nastąpiło odczytanie i podpisanie aktu erekcyjnego oraz poświęcenie i  wmurowanie aktu w ściany budowanego Domu dla Dzieci.',
                },
                {
                    id: 3,
                    type: 'text',
                    text: 'Realizacja zadania inwestycyjnego "Budowa kampusu Domu Dziecka wraz z zagospodarowaniem terenu" wymagała zaangażowania wielu osób, w monitorowanie postępów i realizację podejmowanych etapowo prac względem harmonogramu realizacji umowy. Desygnowani przez Pana Starostę pracownicy starostwa powiatowego w Węgrowie Pani Wanda Mroczek - Naczelnik Wydziału Rozwoju i Budownictwa Powiatu Węgrowskiego, Pani Malwina Jałmużna-Biernat - Zastępca Naczelnika Wydziału Rozwoju i Budownictwa, Pan Józef Sarosiek - Inspektor Nadzoru Inwestorskiego, wspólnie z przedstawicielami wykonawcy Panem Łukaszem Masny i Panem Andrzejem Strugalskim oraz Stanisławem Lewsza - kierownikiem budowy, pod nadzorem Pani Agaty Kuczyńskiej - Dyrektor Domu Dziecka rozpoczęli systematyczne, co dwutygodniowe narady w celu koordynowania zakresu prac, począwszy od 17 maja 2017 roku. Prace postępowały niezwykle harmonijnie i sprawnie. We wrześniu 2017 roku zostało wykonane pokrycie dachu i rozpoczęto wykonywanie wewnętrznych instalacji w budynku "Ignaś", który od początku budowy przodował pozostawiając w tyle "Helenkę", której pokrycie dachu zrealizowano dwa miesiące później, na początku grudnia. Dnia 27 kwietnia 2018 roku odbył się końcowy odbiór inwestycji, której realizacja przebiegła zgodnie z planem. Rozpoczęto wyposażanie domków w niezbędne meble i sprzęty gospodarstwa domowego, uwzględniając potrzeby młodych mieszkańców.',
                },
                {
                    id: 4,
                    type: 'text',
                    text: 'Dzięki zrealizowanej inwestycji dla wszystkich dzieci przebywających w Domu Dziecka "Julin"  zapewnione zostały miejsca zamieszkania w dwóch nowo wybudowanych 14 osobowych domach, które spełniają wymogi i standardy określone w ustawie o wspieraniu rodziny i systemie pieczy zastępczej oraz zapewniają dalszą właściwą pieczę zastępczą dzieciom.',
                },
                {
                    id: 5,
                    type: 'text',
                    text: 'Dnia 1 lipca 2018 roku dokonano oficjalnego otwarcia Placówek Opiekuńczo - Wychowawczych „Ignaś” i „Helenka” w Kaliskach. Dyrektorem placówki jest Pani Agata Kuczyńska.',
                },
                {
                    id: 6,
                    type: 'image',
                    image: 'POW_otwarcie.jpg',
                    alt: 'Uroczyste przecięcie wstęgi podczas otwarcia Placówek Opiekuńczo-Wychowawczych “Ignaś” i “Helenka” w Kaliskach',
                },
            ]
        },
    ]
}
